import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const productTemplate = ({ data }) => {
  console.log(data.contentfulProduct.spaceImage)
  return (
    <Layout>
      <section>
        <div className="w-full">
          <img
            className="object-contain m-auto"
            src={data.contentfulProduct.eyeCatch.file.url}
            alt="ツキ板の画像"
          />
        </div>
        <div className="flex">
          {data.contentfulProduct.image.map(image => (
            <div className="w-1/3">
              <img src={image.fluid.srcWebp} alt="ツキ板の詳細画像" />
            </div>
          ))}
        </div>
      </section>
      <section className="story">
        <div className="text-center text-4xl my-8">STORY</div>
        <hr className="w-2/3 m-auto mb-16"></hr>
        {renderRichText(data.contentfulProduct.story)}
      </section>
      <section>
        <div>
          {data.contentfulProduct.spaceImage !== null ? (
            <img
              src={data.contentfulProduct.spaceImage.fluid.srcWebp}
              alt="ツキ板の詳細画像"
              className="my-4 m-auto"
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
      <section className="spec">
        <div className="text-center text-4xl my-8">SPEC</div>
        <hr className="w-2/3 m-auto mb-16"></hr>
        <table className="table-auto">
          <tbody>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                製品名
              </td>
              <td className="border px-4 py-2">
                {data.contentfulProduct.productName}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                ツキ板
              </td>
              <td className="border px-4 py-2">
                {data.contentfulProduct.veneer}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                ロット
              </td>
              <td className="border px-4 py-2">{data.contentfulProduct.lot}</td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                サイズ
              </td>
              <td className="border px-4 py-2">
                {data.contentfulProduct.size}
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                在庫数
              </td>
              <td className="border px-4 py-2">
                {data.contentfulProduct.quantity}枚
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-center bg-gray-100">
                仕上げ
              </td>
              <td className="border px-4 py-2">
                {data.contentfulProduct.finish}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="warn">
        <div className="text-sm">
          <p>
            <span className="font-bold mt-4 block">
              設計事務所、デザイナーへのお願い
            </span>
            図面にスペックをご記載いただく際は
            <span className="text-red-400">
              必ずお問い合わせをお願いしております。
            </span>
            <span className="font-bold mt-4 block">
              スペックナンバー発行について
            </span>
            数量に限りがある製品になります。ご検討中の数量（検討範囲）をお知らせください。
            <br />
            <span className="text-red-400">
              物件専用のスペックナンバーを発行いたします。
            </span>
            <br />
            <Link to="/support" className="underline">
              詳しくはこちら
            </Link>
            <span className="font-bold mt-4 block">
              Elegant Selectionについて
            </span>
            掲載している画像と同じ木目での製品で、実際に納品するツキ板です。
            <span className="font-bold mt-4 block">他の製品でご検討の場合</span>
            Elegant Selectionページ内の製品以外でもご対応は可能です。
            <br />
            その他ご不明点もお気軽にお問い合わせください。
            <br />
          </p>
        </div>

        <Link to="/support">
          <button className="block border md:w-96 w-full hover: border-red-400 m-auto mt-8 px-16 py-4 text-center shadow-md bg-red-400 text-white hover:text-red-400 hover:bg-white hover:shadow-none outline-0">
            詳しくはこちら
          </button>
        </Link>
        <a href="https://ws.formzu.net/dist/S76204586/">
          <button className="block border md:w-96 w-full hover: border-gray-400 m-auto mt-8 px-16 py-4 text-center shadow-md bg-gray-400 text-white hover:text-gray-400 hover:bg-white hover:shadow-none outline-0">
            お問い合わせ
          </button>
        </a>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      productName
      veneer
      lot
      size
      quantity
      finish
      story {
        raw
      }
      eyeCatch {
        file {
          url
        }
      }
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      spaceImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default productTemplate
